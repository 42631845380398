import {actionsNotifications} from '../../features/app/_BLL/notifications/slice';

export const handleResponse = async <Data>(args: {
	response: Response,
	thunkAPI: any
}): Promise<Data> => {

	const {
		response,
		thunkAPI: {
			rejectWithValue,
			dispatch,
		}
	} = args;

	const parsedResponse = response.status === 204 ? '' : await response.json();

	if (response.status < 200 || response.status >= 300) {
		if (response.status !== 404) { // There is no reason to show error on 404.
			if ('severity' in parsedResponse && 'message' in parsedResponse) {
				const {severity, message, incidentId} = parsedResponse;
				if (severity === 'Information') {
					dispatch(actionsNotifications.addNotification({
						type: 'info',
						message
					}));
				}

				if (severity === 'Warning') {
					dispatch(actionsNotifications.addNotification({
						type: 'warning',
						message
					}));
				}

				if (severity === 'Error') {
					dispatch(actionsNotifications.addNotification({
						type: 'error',
						message
					}));
				}

				if (severity === 'Critical' && parsedResponse.incidentId) {
					dispatch(actionsNotifications.setError({
						message,
						incidentId
					}));
				}
			} else {
				dispatch(actionsNotifications.addNotification({
					type: 'error',
					message: 'Unknown error',
				}));
			}
			// throw instead of return is required for the thunk to stop its execution when error happens. Try/Catch is handled by createAsyncThunk.
			throw rejectWithValue(parsedResponse);
		}
		throw rejectWithValue(parsedResponse);
	} else {
		return parsedResponse as Data;
	}
};
