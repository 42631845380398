import {handleResponse} from './shared';

// dev - 'https://denominator-api-external-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com'
// prod - 'https://denominator-api-external-prod.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com'

const host = process.env.REACT_APP_HOST || 'https://denominator-api-external-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com';

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJXSEZVc2VyIiwibmJmIjoxNzE1NTgxODc3LCJleHAiOjE4NzMzNDgyNzcsImlhdCI6MTcxNTU4MTg3N30.kYhFEEn5Wz3zh5U7oGKhd9KzGOsIz0h-ja6Epdvbekk';

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Headers': '*',
    'x-api-token': token,
  };
};

const get = (url: string, params?: string) =>
  fetch(`${host}/${url}/${params ? '?' + params : ''}`, {
    method: 'GET',
    headers: getHeaders(),
    referrerPolicy: 'no-referrer',
  });

// * RTK api
export const getRequestUrl = (url: string, params?: string) => {
  return `${host}/${url}${params ? '?' + params : ''}`;
};

export const generateParams = (params?: object) => {
  return params
    ? Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
    : '';
};

// * RTK pure requests
interface FetchGET {
  url: string,
  params?: object,
  signal?: AbortSignal
}

const GET = async (args: FetchGET) => {
  const {url, params, signal} = args;

  return await fetch(getRequestUrl(url, generateParams(params)), {
    method: 'GET',
    headers: getHeaders(),
    referrerPolicy: 'no-referrer',
    signal
  });
};

// * RTK request handlers
const getRequest = async <RES>(args: FetchGET & { thunkAPI: any }) => {
  const {thunkAPI, ...getArgs} = args;

  const response = await GET(getArgs);

  return await handleResponse<RES>({
    response,
    thunkAPI
  });
};

// * Export
export const apiRequest = {
  getRequest,
};
