import {TableSimple} from './components/TableSimple';
import s from './RatingPage.module.scss';
import styled from 'styled-components/macro';
import {Header} from '../../core/components/layout/Header/Header';
import {Description} from './components/Description';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../core/BLL/createAction';
import {PreloaderButton} from '../../core/components/ui-elements/Preloaders/PreloaderButton';
import {LayoutStyles} from '../../core/styles/common/layout';
import {Modal} from '../../core/components/Modal/Modal';
import {ModalContent} from './components/ModalContent';
import {actionsRating} from './_BLL/slice';
import {CountryFilter} from '../CountryFilter/CountryFilter';
import {RatingFormatted} from './_BLL/types';

export const RatingPage = () => {

	// * SC
	const LS = LayoutStyles;

	// * Selectors
	const ratings = useAppSelector(state => state.rating.ratings);

	// * Actions
	const dispatch = useAppDispatch();
	const {getRatings} = actionsRating;

	const [showModal, setShowModal] = useState<boolean>(false);

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		dispatch(getRatings());
	}, []);

	// * Country filter
	const [selectedCountriesIds, setSelectedCountriesIds] = useState<string[]>(ratings.map(rating => rating.countryName));
	const [appliedCountriesNames, setAppliedCountriesNames] = useState<RatingFormatted[]>([]);

	useEffect(() => {
		setSelectedCountriesIds(ratings.map(rating => rating.countryName));
	}, [ratings.length]);

	useEffect(() => {
		setAppliedCountriesNames(ratings.filter(rating => selectedCountriesIds.includes(rating.countryName)));
	}, [selectedCountriesIds.length]);

	// * Render
	return (<>
		<Modal
			show={showModal}
			toggleModal={toggleModal}
			Content={<ModalContent/>}
			closeOnBackdrop={false}
			width={20}
		/>

		<Header/>

		<div className={s.container}>
			{ratings.length > 0
				? (
					<Inner>
						<InnerLeft>
							<Description/>
						</InnerLeft>

						<InnerRight>
							{ratings.length > 0 && (
								<CountryFilter
									ratings={ratings}
									selectedCountriesIds={selectedCountriesIds}
									setSelectedCountriesIds={setSelectedCountriesIds}
								/>
							)}

							<TableSimple ratings={appliedCountriesNames}/>
						</InnerRight>
					</Inner>
				) : (
					<LS.PreloaderWrapper>
						<PreloaderButton/>
					</LS.PreloaderWrapper>
				)
			}
		</div>
	</>);
};

// * SC
const Inner = styled.div`
  display: flex;
	
  height: fit-content;
	
	@media (max-width: 1400px) {
    flex-wrap: wrap;
	}
`;

const InnerLeft = styled.div`
  flex: 1 1 calc(35% - 4rem); // excluding margin right

  margin-right: 4rem;
`;

const InnerRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1 1 65%;

  height: 100vh;
`;
