import React, {memo, PropsWithChildren, useState} from 'react';
import styled from 'styled-components/macro';
import {Arrow} from '../Arrow';
import {Checkbox} from '../Checkbox';

interface Props<T, Key> {
	keyNames: {
		id: Key,
		name: Key,
		children: Key
	}, // ? keyNames must be set first in order for TS to work.
	option: T
	allSelectedIds: string[]
	onClick: (option: T) => void
	setMultipleOptions: (option: T[], isSelected: boolean) => void
	handleChecked: (id: number | string) => boolean
}

const OptionsGenerator = <T extends { [key: string]: any }, Key extends keyof T, >(props: PropsWithChildren<Props<T, Key>>) => {

	const {
		option,
		allSelectedIds,
		keyNames,
		onClick,
		setMultipleOptions,
		handleChecked,
	} = props;

	const id = option[keyNames.id];
	const name = option[keyNames.name];
	const children = typeof option[keyNames.children] === 'object' ? option[keyNames.children] as T[] : null;

	const [menuExpended, setMenuExpended] = useState<boolean>(false);
	const expendMenu = () => setMenuExpended(!menuExpended);

	const isChecked = () => {
		return children?.length === children?.filter(id => allSelectedIds.includes(id[keyNames.id])).length
	}

	const onParentClick = () => {
		children && setMultipleOptions(children, isChecked())
	}

	// * Render
	return (<>
		<MenuOption>
			<CheckboxStyled
				checked={isChecked()}
				onClick={onParentClick}
			/>

			<div onClick={expendMenu}>
				<span>{name}</span>
				<ArrowStyled direction={menuExpended ? 'down' : 'right'}/>
			</div>
		</MenuOption>

		{menuExpended && children?.map(item => (
			<ChildMemorized
				key={item.id}
				option={item}
				keyNames={keyNames}
				onClick={onClick}
				handleChecked={handleChecked}
			/>
		))}
	</>);
};

export const OptionsGeneratorMultipleExpandable = memo(OptionsGenerator) as typeof OptionsGenerator;

// * Child component
const Child = <T extends Record<string, any>, Key extends keyof T, >(props: PropsWithChildren<Omit<Props<T, Key>, 'allSelectedIds' | 'setMultipleOptions'>>) => {

	const {
		option,
		onClick,
		handleChecked,
		keyNames,
	} = props;

	const id = option[keyNames.id];
	const name = option[keyNames.name];

	return (<>
			<ChildOption
				onClick={() => onClick(option)}
			>
				<Checkbox
					onClick={() => onClick(option)}
					checked={handleChecked(id)}
				/>
				<span>{name}</span>
			</ChildOption>
		</>
	);
};

const ChildMemorized = React.memo(Child) as typeof Child;

// * SC
const MenuOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  font-size: .88rem;

  & :hover {
    cursor: pointer;
  }

  & > span {
    color: ${({theme}) => theme.palette.primary.main};
`;

const ChildOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
	
	margin-left: 1.5rem;
  padding-right: 1rem;
  min-width: 23rem;

  font-size: .88rem;

  & :hover {
    cursor: pointer;
  }
`;

const CheckboxStyled = styled(Checkbox)`
  & :hover {
    cursor: pointer;
  }
`;

const ArrowStyled = styled(Arrow)`
  margin-left: .4rem;

  width: .5rem;
  height: .5rem;
  fill: ${({theme}) => theme.palette.grey[500]};
`;

