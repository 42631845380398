import {Rating, RatingFormatted} from './types';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RequestStatus} from '../../../core/api/types';
import {apiRequest} from '../../../core/api/api';
import {DictionaryItem} from '../../CountryFilter/CountryFilter';

const NAME = 'ratings';

const getRatings = createAsyncThunk(`${NAME}/getRatings`, async (arg: void, thunkAPI) => {
	return await apiRequest.getRequest<{ hierarchy: DictionaryItem[], ratings: Rating[] }>({
		url: 'countryIndexRatings',
		thunkAPI
	});
});

// * Reducer
interface State {
	hierarchy: DictionaryItem[];
	ratings: RatingFormatted[];
	status: RequestStatus;
	error: string | null;
}

const initialState: State = {
	hierarchy: [],
	ratings: [],
	error: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getRatings.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getRatings.fulfilled, (state, action) => {
			state.hierarchy = action.payload.hierarchy;
			state.ratings = action.payload.ratings.map(rating => ({
				...rating,
				globalRank: {
					rank: rating.globalRank,
					delta: rating.globalRankDelta
				}
			}));
			state.status = RequestStatus.still;
		});
		builder.addCase(getRatings.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsRating = {
	...slice.actions,
	getRatings
};
