import styled from 'styled-components/macro';
import {TypographyStyles} from '../../../core/styles/common/typography';
import {ReactComponent as MainSVG} from '../../../core/assets/svg/mail.svg';
import {Button} from '../../../core/components/ui-elements/Button';

export const ModalContent = () => {

  // Styles
  const TS = TypographyStyles;

  return (<>
    <TitleWrapperStyled>
      <TS.Title>Detailed information and contacts</TS.Title>
    </TitleWrapperStyled>
    <ButtonStyled link="https://country.denominator.com/DenominatorCountry_DocumentationMethodology.pdf">Click here for more information on the construction of the DEI Country Index</ButtonStyled>
    <TS.Paragraph>
      For more detailed information on DEI indexes please contact us.
    </TS.Paragraph>
    <MailCard>
      <MailIcon/>
      <MailLink href="mailto:info@denominator.com">info@denominator.com</MailLink>
    </MailCard>
  </>);
};

// Styles
const TitleWrapperStyled = styled(TypographyStyles.TitleWrapper)`
  margin-bottom: 2rem;
`

const MailCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding: 1rem 1.5rem;

  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
  background-color: ${({theme}) => theme.palette.grey[200]};
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 1.5rem;
`

const MailIcon = styled(MainSVG)`
  margin-right: .6rem;
  width: 2rem;

  fill: ${({theme}) => theme.palette.grey[600]};
`;

const MailLink = styled.a`
  font-size: 1.2rem;
  color: ${({theme}) => theme.palette.primary.main};
`;
