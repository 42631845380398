import React from 'react';
import {ReactComponent as InfoSVG} from '../../../core/assets/svg/info.svg';
import {ReactComponent as ArrowUp} from '../../../core/assets/svg/arrows/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../../../core/assets/svg/arrows/arrow-down.svg';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import styled from 'styled-components/macro';
import {RatingFormatted} from '../_BLL/types';
import {Delta} from './Delta/Delta';
import {Tooltip} from '../../../core/components/tooltips/Tooltip/Tooltip';

interface Props {
	ratings: RatingFormatted[];
}

export const TableSimple: React.FC<Props> = props => {

	const {
		ratings
	} = props;

	const [sorting, setSorting] = React.useState<SortingState>([]);

	const columnHelper = createColumnHelper<RatingFormatted>();

	const columns = [
		columnHelper.accessor('countryName', {
			header: 'Country',
		}),
		columnHelper.accessor('countryRegion', {
			header: 'Region',
		}),
		columnHelper.accessor('countrySubRegion', {
			header: 'Sub Region',
		}),
		columnHelper.accessor('globalRank', {
			header: info => (
				<GlobalRank>
					<span>Global Rank</span>
					<Tooltip text="Rank compared to the previous year">
						<InfoSVG/>
					</Tooltip>
				</GlobalRank>
			),
			cell: info => (
				<Delta {...info.getValue()}/>
			)
		}),
		columnHelper.accessor('regionalRank', {
			header: 'Regional Rank',
		}),
		columnHelper.accessor('subRegionalRank', {
			header: 'Sub Regional Rank',
		})
	];

	const table = useReactTable<any>({
		columns: columns,
		state: {
			sorting
		},
		data: ratings ? ratings : [],
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel()
	});

	// * Render
	return (
		<Container>
			<Table>
				<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th key={header.id} colSpan={header.colSpan}
									{...{onClick: header.column.getToggleSortingHandler()}}
							>
								{header.isPlaceholder ? null : (
									<div
										{...{
											className: header.column.getCanSort()
												? 'cursor-pointer select-none'
												: '',
										}}
									>
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
										{{
											asc: <ArrowUp/>,
											desc: <ArrowDown/>,
										}[header.column.getIsSorted() as string] ?? null}
									</div>
								)}
							</th>
						))}
					</tr>
				))}
				</thead>

				<tbody>
				{table
					.getRowModel()
					.rows.map(row => (
						<tr key={row.id}>
							{row.getVisibleCells().map(cell => {
								return (
									<td key={cell.id}>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext()
										)}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</Table>
		</Container>
	);
};

// * SC
const Container = styled.div`
  height: fit-content;
  overflow-x: auto;
`;

const Table = styled.table`
  position: relative;

  height: 100%;
  width: 100%;
  overflow-y: scroll;

  border-spacing: 0;
  border: 1px solid ${({theme}) => theme.palette.grey[400]};

  box-shadow: ${({theme}) => theme.shadows.color[2]};
  background-color: ${({theme}) => theme.palette.common.white};

  & tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  & th, td {
    margin: 0;
    border-bottom: 1px solid ${({theme}) => theme.palette.grey[400]};
    border-right: 1px solid ${({theme}) => theme.palette.grey[400]};

    text-align: start;

    &:last-child {
      border-right: 0;
    }
  }

  & thead {
    position: sticky;
    top: 0;
    left: 0;

    & tr {
      & th {
        background-color: ${({theme}) => theme.palette.primary.main};

        font-weight: 300;
        font-size: .8rem;
        color: ${({theme}) => theme.palette.common.white};
        text-transform: uppercase;

        & > span {
          padding-right: .5rem;
        }

        & svg {
					position: relative;
					top: -.05rem;
					
					display: flex;
					
          height: .8rem;
          width: .8rem;
          fill: ${({theme}) => theme.palette.common.white};

          &.invisible {
            fill: ${({theme}) => theme.palette.primary.light};
          }
        }

        &:hover {
          cursor: pointer;
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
					
          padding: .5rem .75rem;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        padding: .25rem .75rem;

        font-weight: 500;
        color: ${({theme}) => theme.palette.primary.dark};
      }
    }
  }
`;

const GlobalRank = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`

