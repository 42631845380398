import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './rootReducer'


export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		immutableCheck: false
	}),
	devTools: {trace: false},
});

export type AppDispatch = typeof store.dispatch
