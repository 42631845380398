import React from 'react';
import s from './Description.module.scss';
import styled from 'styled-components/macro';
import {ReactComponent as LogoCountryBoldSVG} from '../../../core/assets/svg/logo-country-bold.svg';
import {ReactComponent as LogoCountryRegularSVG} from '../../../core/assets/svg/logo-country-regular.svg';
import {Button} from '../../../core/components/ui-elements/Button';
import {TypographyStyles} from '../../../core/styles/common/typography';

export const Description: React.FC = () => {

	const currentYear = new Date().getFullYear()

	const denominatorLogo = <b>Denominator<sup>Country</sup></b>

	// * Render
	return (
		<Container>
			<Title>
				<LogoCountryBoldSVG/> <span className={s.year}>{currentYear}</span>
			</Title>

			<Separator/>

			<ParagraphStyled>
				<p>The index {denominatorLogo} provides a comprehensive evaluation tool of countries’ progress toward promoting
					Diversity, Equity, & Inclusion (DEI) across various social, economic, and political
					indicators. {denominatorLogo} measures DEI performance of over 190 countries worldwide across 70+ DEI
					indicators. The
					indicators are grouped into multiple DEI dimensions within the three pillars of Diversity, Equity, & Inclusion
					with sub-dimensions such as Gender, Race/Ethnicity, Age, Sexuality, Education, Health, Disability etc.</p>

				<p>The index consists of two main components. The first component is a country score based on the DEI
					performance across all indicators. The second component is a company score based on the average DEI
					performance of companies operating in the country.</p>

				<p>Through this methodology, the {denominatorLogo} provides a holistic assessment of countries' DEI
					performance. The index empowers policymakers, organizations, and other stakeholders to identify areas of
					strength and weakness and develop strategies to drive positive change. By providing this data, Denominator
					brings transparency to DEI globally and foster a more diverse, equitable, and inclusive world for
					everyone.</p>
			</ParagraphStyled>

			<FormulaWrapper>
				<FormulaCard>DEI Country Performance</FormulaCard>
				<FormulaOperator>+</FormulaOperator>
				<FormulaCard>DEI Company Performance</FormulaCard>
				<FormulaOperator>=</FormulaOperator>
				<FormulaCard>
					<LogoCountryRegularSVG/>
				</FormulaCard>
			</FormulaWrapper>

			<div className={s.infoDetails}>
				<Button link="https://country.denominator.com/DenominatorCountry_DocumentationMethodology.pdf">
					View The Methodology
				</Button>

				<p>For more detailed information on {denominatorLogo}, including historical data dating back to 2020, please contact us. <a
					href="mailto:info@denominator.com">info@denominator.com</a></p>
			</div>
		</Container>
	);
};

// * SC
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
	
	margin-bottom: 2rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  margin-bottom: .5rem;

  & > svg {
    width: 100%;
    fill: ${({theme}) => theme.palette.primary.main}
  }
`;

const Separator = styled.div`
  margin-bottom: 2rem;

  height: .25rem;
  width: 2rem;

  background-color: ${({theme}) => theme.palette.primary.main};
`;

const ParagraphStyled = styled(TypographyStyles.Paragraph)`
  margin-bottom: 3rem;

  & > p:not(:last-child) {
    padding-bottom: 1rem;
  }

  & svg {
		position: relative;
		top: .02rem;
		
    height: 1rem;

    fill: var(--color-primary-1);
  }
`;

const FormulaWrapper = styled.div`
  display: flex;

  margin-bottom: 4rem;

  & > div:not(:last-child) {
    margin-right: 1rem;
  }
`;

const FormulaCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  font-family: 'Ubuntu', sans-serif;
	font-size: .8rem;
  text-align: center;
  font-weight: 600;
  color: var(--color-primary-1);
  white-space: nowrap;
	
  background-color: ${({theme}) => theme.palette.common.white};

  box-shadow: ${({theme}) => theme.shadows.color[2]};

  & > svg {
    height: .8rem;
    fill: var(--color-primary-1);
  }
`;

const FormulaOperator = styled.div`
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  color: var(--color-primary-1);
`;
