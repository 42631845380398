import React from 'react';
import styled, {keyframes} from 'styled-components/macro';

export const PreloaderButton = () => {
  return (
    <LoaderContainer>
      <Card/>
    </LoaderContainer>
  );
};

// Styles
const animate = keyframes`
  50% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const LoaderContainer = styled.div`
  perspective: 300px;
  width: 75px;
  //height: 100%;
  position: relative;
`;

const Card = styled.div`
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 40px;
  border-radius: 4px;
  background: ${({theme}) => theme.palette.primary.main};
  animation: ${animate} 1.5s infinite;
`;