import ReactDOM from 'react-dom';
import React, {HTMLAttributes, memo} from 'react';
import styled from 'styled-components/macro';
import {LayoutStyles} from '../../styles/common/layout';
import {ReactComponent as CloseIcon} from '../../assets/svg/close.svg';
import {Backdrop} from './Backdrop';

interface ModalOverlayProps {
  isActive: boolean
  toggleModal: () => void
  Content: JSX.Element
  width: number
  increaseZIndexBy: number
}

const ModalOverlay: React.FC<ModalOverlayProps> = props => {

	const {
		isActive,
		toggleModal,
		Content,
		width,
		increaseZIndexBy
	} = props;


	const component = (
		<ModalMain
			isActive={isActive}
			width={width}
			increaseZIndexBy={increaseZIndexBy}
		>
			<CardStyled id="modal">
				{Content}
			</CardStyled>
			{toggleModal && (
				<CloseButton>
					<CloseIcon onClick={toggleModal}/>
				</CloseButton>
			)}
		</ModalMain>
	);

	const container = document.getElementById('modal-portal') as HTMLElement;

	return ReactDOM.createPortal(component, container);
};

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean
  toggleModal: () => void
  Content: JSX.Element
  closeOnBackdrop?: boolean
  width?: number
  increaseZIndexBy?: number
}

export const Modal: React.FC<ModalProps> = memo(props => {

	const {
		show,
		toggleModal,
		Content,
		closeOnBackdrop = true,
		width = 80, // Percent
		increaseZIndexBy = 0
	} = props;

	return (<>
		<Backdrop
			isActive={show}
			onClick={closeOnBackdrop ? toggleModal : undefined}
			increaseZIndexBy={increaseZIndexBy}
		/>
		<ModalOverlay
			isActive={show}
			toggleModal={toggleModal}
			Content={Content}
			width={width}
			increaseZIndexBy={increaseZIndexBy}
		/>
	</>);
});

// Styles
const ModalMain = styled.div<{ isActive: boolean, width: number, increaseZIndexBy: number }>`
  position: fixed;
  top: 10rem;
  left: ${({width}) => (100 - width) / 2}%;
  z-index: ${({increaseZIndexBy}) => 1001 + increaseZIndexBy};

  display: ${({isActive}) => isActive ? 'block' : 'none'};

  align-items: center;
  justify-content: center;

  width: ${({width}) => width}%;
`;

const CardStyled = styled(LayoutStyles.Card)`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
`;

const CloseButton = styled.div`
  position: absolute;
  top: -45px;
  right: -45px;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  background-color: ${({theme}) => theme.palette.common.white};

  box-shadow: ${({theme}) => theme.shadows.grey[2]};
  cursor: pointer;

  & svg {
    position: absolute;
    top: 5px;
    left: 5px;

    width: 30px;
    height: 30px;

    fill: ${({theme}) => theme.palette.primary.main};
  }

  &:hover {
    & svg {
      fill: ${({theme}) => theme.palette.secondary.main}
    }
  }
`;
