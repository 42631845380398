import React, {useState} from 'react';
import s from './CountryFilter.module.scss';
import styled from 'styled-components/macro';
import {OptionsGeneratorExpandable} from '../../core/components/ui-elements/optionList/OptionsGeneratorExpandable';
import {
	OptionsGeneratorMultipleExpandable
} from '../../core/components/ui-elements/optionList/OptionsGeneratorMultipleExpandable';
import {Button} from '../../core/components/ui-elements/Button';
import {RatingFormatted} from '../rating/_BLL/types';
import {removeDuplicates} from '../../helpers/array';
import {Arrow, Direction} from '../../core/components/ui-elements/Arrow';
import {useAppSelector} from '../../core/BLL/createAction';

export type DictionaryItem = {
	name: string;
	children?: DictionaryItem[];
}

interface Props {
	ratings: RatingFormatted[];
	selectedCountriesIds: string[];
	setSelectedCountriesIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CountryFilter: React.FC<Props> = props => {

	const {
		ratings,
		setSelectedCountriesIds,
		selectedCountriesIds
	} = props;

	const regions = useAppSelector(state => state.rating.hierarchy)

	// // * Create dictionary
	// let regions: DictionaryItem[] = [];
	//
	// ratings.forEach(rating => {
	// 	if (!regions.find(region => region.name === rating.countryRegion)) {
	// 		regions.push({
	// 			name: rating.countryRegion,
	// 			children: ratings
	// 				.filter(ratingSub => ratingSub.countryRegion === rating.countryRegion)
	// 				.map(subregion => ({
	// 					name: subregion.countrySubRegion,
	// 					children: ratings
	// 						.filter(ratingCountry => ratingCountry.countrySubRegion === subregion.countrySubRegion)
	// 						.map(country => ({
	// 							name: country.countryName,
	// 						}))
	// 				}))
	// 		});
	// 	}
	// });
	//
	// console.log('regions', regions);
	//
	// regions = regions.map(region => ({
	// 	...region,
	// 	children: region.children ? removeDuplicates(region.children, 'name') : []
	// }));

	// * Filter collapse
	const [open, setOpen] = useState(false);
	const toggleFillers = () => setOpen(prevState => !prevState);
	const getArrowDirection = (): Direction => {
		return open ? 'up' : 'down';
	};

	// * Country selection
	const [selectedRegionId, setSelectedRegionId] = useState<any>(null);

	const handleCountriesAndRegionsClick = (option: { name: string, children?: { name: string }[] }) => {
		setSelectedCountriesIds(prevState => {
			const optionExists = prevState.includes(option.name);
			return optionExists ? prevState.filter(id => id !== option.name) : [...prevState, option.name];
		});
	};

	const setMultipleCountries = (options: { name: string, children?: { name: string }[] }[], isSelected: boolean) => {
		const prevSelectedOptionNames: string[] = [];
		const prevNotSelectedOptionsNames: string[] = [];
		options.forEach(option => {
			selectedCountriesIds.includes(option.name)
				? prevSelectedOptionNames.push(option.name)
				: prevNotSelectedOptionsNames.push(option.name);
		});

		isSelected
			? setSelectedCountriesIds(prevState => prevState.filter(prevOption => !prevSelectedOptionNames.includes(prevOption) ))
			: setSelectedCountriesIds(prevState => [...prevState, ...prevNotSelectedOptionsNames])
	};

	// * Render
	return (
		<div className={s.container}>
			<div
				className={s.header}
				onClick={toggleFillers}
			>
				<h3>Countries Filter</h3>
				<Arrow direction={getArrowDirection()}/>
			</div>

			{open && (<>
				<div className={s.inner}>
					{/* Region column */}
					<div>
						<h4>Search Criteria</h4>

						<SubtitleWrapper>
							<Subtitle>Region</Subtitle>
						</SubtitleWrapper>

						<RegionOptionsContainer>
							{regions.map(region => (
								<OptionsGeneratorExpandable
									key={region.name}
									keyNames={{
										id: 'name',
										name: 'name',
										children: 'children'
									}}
									option={region}
									onClick={option => setSelectedRegionId(option.name)}
									selectedOption={selectedRegionId}
									disableChildren={true}
								/>
							))}
						</RegionOptionsContainer>
					</div>

					{/* Subregion/Country column */}
					<div>
						<h4>Subregion/Country</h4>

						<SubtitleWrapper>
							<Subtitle>By subregions category</Subtitle>
						</SubtitleWrapper>

						<div className={s.optionsList}>
							{selectedRegionId && regions?.filter(region => region.name === selectedRegionId)[0].children?.map(child => (
								<OptionsGeneratorMultipleExpandable
									keyNames={{
										id: 'name',
										name: 'name',
										children: 'children'
									}}
									option={child}
									allSelectedIds={selectedCountriesIds}
									onClick={handleCountriesAndRegionsClick}
									setMultipleOptions={setMultipleCountries}
									handleChecked={(id) => {
										return selectedCountriesIds.includes(id.toString());
									}}
								/>
							))}
						</div>
					</div>
				</div>

				<div className={s.buttons}>
					<Button
						onClick={() => {
							setSelectedCountriesIds(ratings.map(rating => rating.countryName));
						}}
						size="small"
					>
						Select all
					</Button>

					<Button
						onClick={() => {
							setSelectedCountriesIds([]);
						}}
						size="small"
					>
						Clear all
					</Button>

					<h4>Countries selected: {selectedCountriesIds.length}</h4>
				</div>
			</>)}
		</div>
	);
};

// * SC
const SubtitleWrapper = styled.div`
  margin-bottom: .6rem;
`;

const Subtitle = styled.span`
  font-size: .8rem;
  color: ${({theme}) => theme.palette.primary.light}
`;

const RegionOptionsContainer = styled.div`
  margin-left: -1rem;
`;
