import {combineReducers} from 'redux';
import {slice as rating} from '../../features/rating/_BLL/slice';
import {slice as notifications} from '../../features/app/_BLL/notifications/slice';

const rootReducer = combineReducers({
	rating: rating.reducer,
	notifications: notifications.reducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
