import React from 'react';
import s from './Delta.module.scss'
import {ReactComponent as ArrowUpSVG} from '../../../../core/assets/svg/arrows/arrow_up_filled.svg';
import {ReactComponent as ArrowDownSVG} from '../../../../core/assets/svg/arrows/arrow_down_filled.svg';
import {ReactComponent as NoDeltaSVG} from '../../../../core/assets/svg/arrows/no_delta.svg';
import {RatingFormatted} from '../../_BLL/types';

type Props = Pick<RatingFormatted, 'globalRank'>['globalRank']

export const Delta: React.FC<Props> = props => {
	const {
		rank,
		delta
	} = props;

	const getArrow = () => {
		if (delta > 0) return <ArrowUpSVG className={s.delta_up}/>
		else if (delta < 0) return <ArrowDownSVG className={s.delta_down}/>
		else return <NoDeltaSVG className={s.delta_none}/>
	}

	// * Render
	return (
		<div className={s.container}>
			{rank}

			<div className={s.delta}>
				{getArrow()} {delta}
			</div>
		</div>
	);
};
