import React from 'react';
import bs_primary from './Button__primary.module.scss';
import bs_secondary from './Button__secondary.module.scss';
import bs_tertiary from './Button__tertiary.module.scss';

interface Props {
	title: string;
	url: string;
	variant?: 'primary' | 'secondary' | 'tertiary';
}

export const ButtonLink: React.FC<Props> = props => {
	const {
		title,
		url,
		variant = 'primary'
	} = props;

	// * Styles
	const containerStyles = variant === 'primary' ? bs_primary : variant === 'secondary' ? bs_secondary : bs_tertiary;

	// * Render
	return (
		<a className={`${containerStyles.container}`} href={url} target="_blank" rel="noreferrer">{title}</a>
	);
};
