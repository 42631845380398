import React, {memo, PropsWithChildren, useState} from 'react';
import styled from 'styled-components/macro';
import {Arrow} from '../Arrow';

interface Props<T, Key> {
	keyNames: {
		id: Key,
		name: Key,
		children: Key
	}, // ? keyNames must be set first in order for TS to work.
	option: T
	onClick: (option: T) => void
	selectedOption: number | string | null
	disableChildren?: boolean
	disableParent?: boolean
	initialExpend?: boolean
	childNumber?: number
}

export const OptionsGenerator = <T extends Record<string, any>, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {

	const {
		option,
		keyNames,
		onClick,
		selectedOption,
		disableChildren,
		disableParent,
		initialExpend = false,
		childNumber = 0
	} = props;

	const {
		id,
		name,
		children
	} = keyNames;

	const [menuExpended, setMenuExpended] = useState<boolean>(initialExpend);
	const expendMenu = () => setMenuExpended(!menuExpended);

	// Conditions
	const childrenEnabled = option[children] && !disableChildren;

	const parentOnClick = childrenEnabled ? () => expendMenu() : () => onClick(option);

	const childrenArray = typeof option[children] === 'object' ? option[children] as T[] : null;

	return (<>
		{!disableParent && (
			<Option
				onClick={parentOnClick}
				isSelected={option[id] === selectedOption}
				childNumber={childNumber}
			>
				<span>{option[name]}</span>
				{childrenEnabled && <ArrowStyled direction={menuExpended ? 'down' : 'right'}/>}
			</Option>
		)}

		{childrenEnabled && menuExpended && (<>
			{childrenArray?.map(option => (
				<OptionsGenerator
					key={option[id]}
					option={option}
					keyNames={keyNames}
					onClick={onClick}
					selectedOption={selectedOption}
					childNumber={childNumber + 1}
				/>
			))}
		</>)}
	</>);
};

export const OptionsGeneratorExpandable = memo(OptionsGenerator) as typeof OptionsGenerator;

// * SC
const Option = styled.li<{ isSelected: boolean, childNumber: number }>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: ${({childNumber}) => childNumber > 0 ? `.2rem ${childNumber * 2}rem` : '.2rem 1rem'};

  font-size: .9rem;

  background-color: ${({theme, isSelected}) => isSelected ? theme.palette.primary[100] : 'inherit'};

  & :hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.palette.primary[200]};
  }

  & > li {
    padding: .2rem 2rem .2rem 2rem;
  }
`;

const ArrowStyled = styled(Arrow)`
  margin-left: .4rem;

  width: .5rem;
  height: .5rem;
  fill: ${({theme}) => theme.palette.grey[500]};
`;
