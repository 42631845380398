import React from 'react';
import {ThemeProvider} from 'styled-components/macro';
import {NormalizeCss} from './core/styles/NormalizeCss';
import {GlobalStyles} from './core/styles/GlobalStyles';
import {RatingPage} from './features/rating/RatingPage';
import {theme} from './core/styles/theme';
import {store} from './core/BLL/store';
import {Provider} from 'react-redux';

export const App: React.FC = () => {
  return (<>
    <ThemeProvider theme={theme}>
      <NormalizeCss/>
      <GlobalStyles/>
      <Provider store={store}>
        <RatingPage/>
      </Provider>
    </ThemeProvider>

  </>);
};
