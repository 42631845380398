import React, {ButtonHTMLAttributes} from 'react';
import styled from 'styled-components/macro';

type Size = 'small' | 'main'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
	size?: Size
	link?: string
}

export const Button: React.FC<Props> = props => {

	const {
		size = 'main',
		link,
		...rest // button props
	} = props;

	return (<>
		{link
			?
			<a href={link} target="_blank" rel="noreferrer">
				<StyledButton {...rest} size={size}>
					{props.children}
				</StyledButton>
			</a>
			:
			<StyledButton {...rest} size={size}>
				{props.children}
			</StyledButton>
		}
	</>);
};

// * SC
const StyledButton = styled.button<{size: Size}>`
  padding: ${({size}) => size === 'main' ? '1rem 2rem' : '.75rem 1.5rem'};

  border: none;
  background-color: ${({theme, disabled}) => disabled ? theme.palette.grey[200] : theme.palette.primary.main};

  color: ${({theme, disabled}) => disabled ? theme.palette.common.black : theme.palette.common.white};
  font-family: 'Ubuntu', sans-serif; // Make a default font actually default
  font-weight: ${({size}) => size === 'main' ? 600 : 600};

  box-shadow: ${({theme}) => theme.shadows.color[4]};
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  transition: all .1s ease-in-out;

  &:hover {
    background-color: ${({theme, disabled}) => disabled ? 'none' : theme.palette.primary.dark};
    transform: ${({disabled}) => disabled ? 'none' : 'translateY(-0.1em)'};
    box-shadow: ${({theme, disabled}) => disabled ? theme.shadows.color[4] : theme.shadows.color[8]};
  }

  &:active {
    background-color: ${({theme, disabled}) => disabled ? 'none' : theme.palette.primary.light};
    transform: ${({disabled}) => disabled ? 'none' : 'translateY(0)'};
    box-shadow: ${({theme, disabled}) => disabled ? theme.shadows.color[4] : theme.shadows.color[8]};
  }
`;
