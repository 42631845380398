import React, {DetailedHTMLProps, HTMLAttributes, memo} from 'react';
import s from './Logo.module.scss';
import {ReactComponent as LogoSVG} from '../../assets/svg/denominator_logo.svg';
import cn from 'classnames';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	variant?: 'dark' | 'light';
}

export const Logo: React.FC<Props> = memo(props => {

	const {
		variant = 'dark'
	} = props;

	const containerStyle = cn(s.container, s[`container_${variant}`]);

	// * Render
	return (
		<div {...props} className={containerStyle}>
			<LogoSVG/>
		</div>
	);
});
