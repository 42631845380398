import styled from 'styled-components/macro';

const Card = styled.div`
  padding: 1rem;

  background: ${({theme}) => theme.palette.common.white};
  box-shadow: ${({theme}) => theme.shadows.grey[1]};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
`;

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 100%;
`;

export const LayoutStyles = {
  Card,
  PreloaderWrapper
};
