import styled from 'styled-components/macro';

const Title = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({theme}) => theme.palette.primary.dark};
`;

const TitleWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Paragraph = styled.div`
  color: ${({theme}) => theme.palette.primary.main};
`;

export const TypographyStyles = {
  Title,
  TitleWrapper,
  Paragraph
};