import ReactDOM from 'react-dom';
import React, {ReactNode} from 'react';
import styled from 'styled-components/macro';

interface Props {
  isActive: boolean;
  onClick: any;
  increaseZIndexBy: number;
	children?: ReactNode | undefined
}

export const Backdrop: React.FC<Props> = props => {

	const {
		isActive,
		onClick,
		increaseZIndexBy,
		children
	} = props;

	const content = (
		<BackDropMain
			isActive={isActive}
			onClick={onClick}
			increaseZIndexBy={increaseZIndexBy}
		>
			{children}
		</BackDropMain>
	);
	const container = document.getElementById('backdrop-portal') as HTMLElement;

	return ReactDOM.createPortal(content, container);
};

// Styles
const BackDropMain = styled.div<{ isActive: boolean, increaseZIndexBy: number }>`
  display: ${({isActive}) => isActive ? 'block' : 'none'};

  position: fixed;
  z-index: ${({increaseZIndexBy}) => 1001 + increaseZIndexBy};

  height: 100%;
  width: 100vw;

  background-color: rgba(.5, .5, .5, .5);
`;
