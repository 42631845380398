import React from 'react';
import {ReactComponent as ArrowLeftSVG} from '../../assets/svg/arrows/arrow-left.svg';
import {ReactComponent as ArrowRightSVG} from '../../assets/svg/arrows/arrow-right.svg';
import {ReactComponent as ArrowUpSVG} from '../../assets/svg/arrows/arrow-up.svg';
import {ReactComponent as ArrowDownSVG} from '../../assets/svg/arrows/arrow-down.svg';

export type Direction = 'up' | 'down' | 'left' | 'right'

interface Props {
	className?: string
	direction?: Direction;
	onClick?: () => void,
}

export const Arrow: React.FC<Props> = props => {

	const {
		className,
		direction,
		onClick,
	} = props;

	// TODO: Make the arrow rotate instead of replacing an SVG! Reason: Size adjustments problems and lack of animation.
	if (!direction || direction === 'right') return <ArrowRightSVG className={className} onClick={onClick}/>;
	if (direction === 'up') return <ArrowUpSVG className={className} onClick={onClick}/>;
	if (direction === 'down') return <ArrowDownSVG className={className} onClick={onClick}/>;
	if (direction === 'left') return <ArrowLeftSVG className={className} onClick={onClick}/>;

	return null;
};
