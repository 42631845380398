import {createGlobalStyle} from 'styled-components/macro';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    font-size: 16px;

    // 1280 - 1919
    @media (max-width: ${({theme}) => theme.breakpoints.values.xl - 1 + 'px'})
    and (min-width: ${({theme}) => theme.breakpoints.values.lg + 'px'}) {
      font-size: 16px;
    }

    // 960 - 1279
    @media (max-width: ${({theme}) => theme.breakpoints.values.lg - 1 + 'px'})
    and (min-width: ${({theme}) => theme.breakpoints.values.md + 'px'}) {
      font-size: 14px;
    }

    // 600 - 959
    @media (max-width: ${({theme}) => theme.breakpoints.values.md - 1 + 'px'})
    and (min-width: ${({theme}) => theme.breakpoints.values.sm + 'px'}) {
      font-size: 12px;
    }

    // 0 - 599
    @media (max-width: ${({theme}) => theme.breakpoints.values.sm - 1 + 'px'}) {
      font-size: 7px;
    }
  }

  body {
    width: 100vw;
    overflow-x: hidden;
    background-color: ${({theme}) => theme.palette.background.default};
  }

  ::-webkit-scrollbar {
    width: .75rem;
    height: .75rem;
  }

  ::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.primary[200]};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.primary.main};
    border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
  }
`;
